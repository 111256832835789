<template>
    <div class="row">
        <div class="col-12">
            <h4>{{ $t('trigger.entity_type.resultat_analyse') }}</h4>
        </div>
        <div class="col-12">
        	<QuestionForm
				ref="QuestionForm"
				question_type="horse_actes_result_type"
				:question_fk="result_type.resulttype_id "
				reponse_type="horse_actes_result"
				:reponse_fk="results[0].result_id"
				:params_wbs="paramsWbs"
			/>
        </div>
        <div class="col-12">
	        <div class="text-center mt-3">
	            <button @click="saveResult" class="btn btn-primary rounded-pill">
	                <font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /><font-awesome-icon v-else :icon="['far', 'save']" /> {{ $t('global.valider') }}
	            </button>
	        </div>
	    </div>
    </div>
</template>

<script type="text/javascript">
	import ResultatAnalyse from '@/mixins/ResultatAnalyse'
    import Shutter from "@/mixins/Shutter.js"
    import ShutterTriggers from '@/mixins/shutters-manager/Triggers.js'
    import Common from '@/assets/js/common.js'

    export default {
        name: "SanitaireResult",
        props: {
            results: { type : Array, default: () => [] },
            is_shutter: { type : Boolean, default: true }
        },
        mixins: [ResultatAnalyse, Shutter, ShutterTriggers],
        data () {
            return {
                result_type: {},
                result: {},
                actes_ids: [],
                processing: false
            }
        },
        mounted() {
            this.init_component()
        },
        methods: {
            async init_component() {
        		this.result_type = await this.getResultTypeById(this.results[0].result_type)
        		this.actes_ids = this.results.map(result => result.acte.actes_id)
            },

            async saveResult() {
                this.processing = true
            	const results_ids = this.results.map(result => result.result_id)
                const reponses = await this.$refs.QuestionForm.prepareReponses()
                // await this.updateResultAnalyse(results_ids, reponses)
                await this.updateResultAnalyseLot([{results_ids, reponses}])
                this.processing = false
                if(this.is_shutter) {
                    this.ok()
                }
                else {
                    this.$emit('submit')
                }
            }
        },
        computed: {
        	paramsWbs() {
                return {
                    acte_type_code: this.result_type.resulttype_id
                }
			}
        },
        components: {
            QuestionForm: () => import('@/components/Questions/QuestionForm'),
        }
    };
</script>